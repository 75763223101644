import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    protected readonly ROUTE_GUEST: string = '/';
    protected readonly ROUTE_NON_GUEST: string = '/';

    protected isAuthReady: boolean = false;

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        private router: Router,
        private authService: AuthService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        if (isPlatformServer(this.platform)) {
            return new Promise((resolve, reject) => {
                let timeout = null;
                let authReadySubscribe: Subscription = this.authService.getAuthReady()
                    .subscribe(status => {
                        timeout && clearTimeout(timeout);

                        if (String(status).toLowerCase() === 'true') {
                            setTimeout(() => authReadySubscribe.unsubscribe(), 10);
                            resolve(this.checkPermissions(route, state));
                        } else {
                            timeout = setTimeout(() => {
                                setTimeout(() => authReadySubscribe.unsubscribe(), 10);
                                resolve(this.checkPermissions(route, state));
                            }, 1000); // 1s delay
                        }
                    }, error => {
                        reject();
                    });
            });
        }

        let authReadySubscribe = this.authService.getAuthReady().pipe(
            filter(status => status),  // don't care about "false" statuses
            map(status => {
                this.isAuthReady = String(status).toLowerCase() === 'true';
                const isGranted = this.checkPermissions(route, state);
                return isGranted;
            }),
            first()
        );

        // we have everything we need to make checkings
        if (this.isAuthReady) {
            const isGranted = this.checkPermissions(route, state);
            return isGranted;
        }

        // we wait until auth finish fetching data from the server
        return authReadySubscribe;
    }

    checkPermissions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let isGuest: boolean = this.authService.user.isUserGuest();

        if ('data' in route && 'guest' in route.data) {
            if (isGuest && !route.data.guest) {
                this.router.navigate([this.ROUTE_GUEST]);
                return false;
            } else if (!isGuest && route.data.guest) {
                this.router.navigate([this.ROUTE_NON_GUEST]);
                return false;
            }
        }

        let current = route.root;
        while (current.children[0] !== undefined) {
            current = current.children[0];
        }

        if (!('data' in current)
            || !('permissions' in current.data)
            || !current.data.permissions
            || this.authService.user.can(current.data.permissions)
        ) {
            return true;
        } else {
            if (isGuest) {
                this.router.navigate([this.ROUTE_GUEST]);
            } else {
                this.router.navigate([this.ROUTE_NON_GUEST]);
            }
            return false;
        }
    }
}
