import { Party } from "./party.model";

export class UserBase {
    id?: number;
    email?: string;
    name_first?: string;
    name_middle?: string;
    name_last?: string;
    initials?: string;
    photo?: string;
    description?: string;
    phone?: string;
    address?: string;
    party?: Party;
    status?: string;

    get name(): string {
        return [this.name_first || '', this.name_last || '']
            .filter(i => i && i.length)
            .join(' ');
    }

    get full_name(): string {
        return [this.name_first || '', this.name_middle || '', this.name_last || '']
            .filter(i => i && i.length)
            .join(' ');
    }
}
