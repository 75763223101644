<alert type="error" [message]="messageError" (remove)="messageError = ''"></alert>

<form class="my-3" (submit)="onSubmit($event)" [formGroup]="form" (keyup.enter)="onSubmit($event)">
    <div class="form-group row">
        <label for="inputLoginEmail" class="col-xs-12 col-sm-4 col-form-label">E-mail</label>
        <div class="col-xs-12 col-sm-8" >
            <input
                type="email"
                class="form-control"
                id="inputLoginEmail"
                [attr.disabled]="loading ? true : null"
                autofocus
                formControlName="email"
                placeholder="Въведете електронен адрес"
                [class.is-invalid]="errors?.email?.status === 'INVALID'"
            />

            <div class="invalid-feedback" *ngIf="errors?.email?.errors?.required">Полето е задължително</div>
            <div class="invalid-feedback" *ngIf="errors?.email?.errors?.minlength && !errors?.name?.errors?.required">Полето е по-малко от 4 символа</div>
            <div class="invalid-feedback" *ngIf="errors?.email?.errors?.email && !errors?.email?.errors?.minlength">Въведете валиден e-mail адрес</div>
            <div class="invalid-feedback" *ngIf="errors?.email?.errors?.any">{{ errors?.email?.errors?.any }}</div>
        </div>
    </div>
    <div class="form-group row">
        <label for="inputLoginPassword" class="col-xs-12 col-sm-4 col-form-label">Парола</label>
        <div class="col-xs-12 col-sm-8">
            <input
                type="password"
                class="form-control"
                id="inputLoginPassword"
                [attr.disabled]="loading ? true : null"
                formControlName="password"
                placeholder="Въведете парола"
                [class.is-invalid]="errors?.password?.status === 'INVALID'"
            />
            <div class="invalid-feedback" *ngIf="errors?.password?.errors?.required">Полето е задължително</div>
        </div>
    </div>
</form>
