import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Meeting } from 'src/app/core/models/meeting.model';
import { Paginate } from 'src/app/core/models/paginate.model';
import { FilterSort } from 'src/app/core/models/filter-sort.model';
import { MeetingOnline, MeetingOnlineStatus } from 'src/app/core/models/meeting-online.model';
import { MeetingAttachment } from 'src/app/core/models/meeting-attachment.model';
import { MeetingVideo } from 'src/app/core/models/meeting-video.model';
import { UploadModel } from '../models/upload.model';
import { MeetingParticipant } from '../models/meeting-participant.model';
import { MeetingAttachmentMultiple } from '../models/meeting-attachment-multiple.model';

export interface MeetingServiceFilter {
    q?: string;
    sort?: string|FilterSort[];
    page?: number;
    limit?: number;
    status?: string|string[];
    live_status?: string|string[];
    committee?: number;
};


export interface MeetingServiceInput {
    committee_id?: number;
    title?: String;
    description?: String;
    room_id?: number;
    live_video_url?: String;
    live_status?: ("no"|"yes");
    start_at?: String;
    end_at?: String;
    position?: number;
    status?: ("active"|"inactive");
};

export interface MeetingServiceResumeInput {
    votes?: ('default'|'full');
    missing?: ('listed'|'calculated');
    sequence?: string;
    format?: ('pdf'|'docx'|'odf'|'rtf'|'html');
};

export interface MeetingServiceInputAttachment {
    title?: string;
    description?: string;
    status?: ('active'|'inactive');
    visible?: ('public'|'private');
};

export interface MeetingServiceInputAddAttachment extends MeetingServiceInputAttachment {
    path?: string;
    mime?: string;
    size?: number;
    position?: number;
};

export interface MeetingServiceAttachmentFilter {
    sort?: string|FilterSort[];
    page?: number;
    limit?: number;
    status?: string|string[];
};

export interface MeetingServiceVideoFilter {
    page?: number;
    limit?: number;
    status?: string|string[];
};

export interface MeetingServiceInputVideo {
    title?: string;
    description?: string;
    embed?: string;
    position?: number;
    duration?: number;
    status?: ('active'|'inactive');
};

@Injectable({
    providedIn: 'root'
})
export class MeetingService {

    constructor(
        private api: ApiService,
    ) { }

    /**
     * Get list of meetings
     *
     * @param filter
     * @returns
     */
    getList(filter?: MeetingServiceFilter): Observable<Paginate<Meeting>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort));
        filter && filter?.status && Array.isArray(filter.status) && (filter['status'] = filter.status.join(','));
        filter && filter?.live_status && Array.isArray(filter.live_status) && (filter['live_status'] = filter.live_status.join(','));

        return this.api.get<Paginate<Meeting>>("/meetings", {params: filter});
    }

    /**
     * Get single item from meetings
     *
     * @param id
     * @returns
     */
    getItem(id: number): Observable<{data: Meeting}> {
        return this.api.get<{data: Meeting}>("/meetings/" + id);
    }

    /**
     * Add new meeting
     *
     * @param data
     * @returns
     */
    add(data: MeetingServiceInput): Observable<{data: Meeting}> {
        return this.api.post("/meetings", data);
    }

    /**
     * Edit existing meeting
     *
     * @param id
     * @param data
     * @returns
     */
    edit(id: number, data: MeetingServiceInput): Observable<{data: Meeting}> {
        return this.api.post("/meetings/" + id, data);
    }

    /**
     * Delete a meeting
     *
     * @param id
     * @returns
     */
    delete(id: number): Observable<{}> {
        return this.api.delete("/meetings/" + id);
    }

    /**
     * Generate and fetch resume file from votes
     *
     * @param id
     * @returns
     */
    resume(id: number, data?: MeetingServiceResumeInput): Observable<{data: UploadModel}> {
        return this.api.post("/meetings/" + id + "/resume", data);
    }

    /**
     * Get meeting attachment files list
     *
     * @param id
     * @param filter
     * @returns
     */
    attachmentGetList(id: number, filter?: MeetingServiceAttachmentFilter): Observable<Paginate<MeetingAttachment>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort));
        filter && filter?.status && Array.isArray(filter.status) && (filter['status'] = filter.status.join(','));

        return this.api.get('/meetings/' + id + '/attachments', {params: filter});
    }

    /**
     * Edit position of Multiple attachments within a meeting
     * @param id
     * @param data
     * @returns
     */
    attachmentSort(id: number, data: Array<{id: number, position: number}>): Observable<{data: MeetingAttachmentMultiple[]}> {
        return this.api.patch('/meetings/' + id + '/attachments/sort', {data});
    }

    /**
     * Get single attachment file from meeting
     *
     * @param id
     * @param attachId
     * @returns
     */
    attachmentGetItem(id: number, attachId: number): Observable<{data: MeetingAttachment}> {
        return this.api.get('/meetings/' + id + '/attachments/' + attachId);
    }

    /**
     * Add a new attachment file to meeting
     *
     * @param id
     * @param data
     * @returns
     */
    attachmentAdd(id: number, data: MeetingServiceInputAddAttachment): Observable<{data: MeetingAttachment}> {
        return this.api.post('/meetings/' + id + '/attachments', data);
    }

    /**
     * Edit an existing attachment file from meeting
     *
     * @param id
     * @param attachId
     * @param data
     * @returns
     */
    attachmentEdit(id: number, attachId: number, data: MeetingServiceInputAttachment): Observable<{data: MeetingAttachment}> {
        return this.api.post('/meetings/' + id + '/attachments/' + attachId, data);
    }

    /**
     * Delete single existing attachment file from meeting
     *
     * @param id
     * @param attachId
     * @returns
     */
    attachmentDelete(id: number, attachId: number): Observable<{}> {
        return this.api.delete('/meetings/' + id + '/attachments/' + attachId);
    }

    /**
     * Get list of user online statuses in meeting
     *
     * @param id
     * @returns
     */
    onlineList(id: number): Observable<{data: MeetingOnline[]}> {
        return this.api.get('/meetings/' + id + '/online');
    }

    /**
     * Update status for current user in meeting
     *
     * @param id
     * @param input
     * @returns
     */
    onlineUpdate(id: number, input: {status?: MeetingOnlineStatus, init?: boolean, user?: number}): Observable<{}> {
        return this.api.post('/meetings/' + id + '/online', input);
    }

    /**
     * Get list of archive videos for meeting
     *
     * @param id
     * @param filter
     * @returns
     */
    videoGetList(id: number, filter?: MeetingServiceVideoFilter): Observable<Paginate<MeetingVideo>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        // filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort));
        filter && filter?.status && Array.isArray(filter.status) && (filter['status'] = filter.status.join(','));

        return this.api.get('/meetings/' + id + '/videos', {params: filter});
    }

    /**
     * Edit position of Multiple videos within a meeting
     * @param id
     * @param data
     * @returns
     */
    videoSort(id: number, data: Array<{id: number, position: number}>): Observable<{data: MeetingAttachmentMultiple[]}> {
        return this.api.patch('/meetings/' + id + '/videos/sort', {data});
    }

    /**
     * Get single video from meeting
     *
     * @param id
     * @param videoId
     * @returns
     */
    videoGetItem(id: number, videoId: number): Observable<{data: MeetingVideo}> {
        return this.api.get('/meetings/' + id + '/videos/' + videoId);
    }

    /**
     * Add new archive video in meeting
     * @param id
     */
    videoAdd(id: number, data: MeetingServiceInputVideo): Observable<{data: MeetingVideo}> {
        return this.api.post('/meetings/' + id + '/videos', data);
    }

    /**
     * Edit existing archive video in meeting
     *
     * @param id
     * @param videoId
     * @param data
     * @returns
     */
    videoEdit(id: number, videoId: number, data: MeetingServiceInputVideo): Observable<{data: MeetingVideo}> {
        return this.api.post('/meetings/' + id + '/videos/' + videoId, data);
    }

    /**
     * Delete single existing attachment file from meeting
     *
     * @param id
     * @param attachId
     * @returns
     */
    videoDelete(id: number, videoId: number): Observable<{}> {
        return this.api.delete('/meetings/' + id + '/videos/' + videoId);
    }

    /**
     * Get list of  participants in meeting (zoom.us)
     *
     * @param id
     * @param attachId
     * @returns
     */
    participantGetList(id: number): Observable<{data: MeetingParticipant[]}> {
        return this.api.get('/meetings/' + id + '/participants');
    }

    /**
     * Add or update participant to meeting (zoom.us)
     *
     * @param id
     * @param attachId
     * @returns
     */
    participantAdd(id: number, uid: number): Observable<{data: MeetingParticipant}> {
        return this.api.post('/meetings/' + id + '/participants/' + uid);
    }

    /**
     * Remove participant from meeting (zoom.us)
     *
     * @param id
     * @param attachId
     * @returns
     */
    participantDelete(id: number, uid: number): Observable<{}> {
        return this.api.delete('/meetings/' + id + '/participants/' + uid);
    }
}
