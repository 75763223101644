import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UndoClickService {

    protected subjectStatus: Subject<Event> = new Subject();

    constructor() { }

    onClickEvent(): Observable<Event> {
        return this.subjectStatus.asObservable().pipe(share());
    }

    setClick(event: Event) {
        this.subjectStatus.next(event);
    }
}
