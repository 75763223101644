import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DomService {

    constructor() { }

    closestTo(el: any, selector: string): any|null {
        do {
            if ((el.matches || el.matchesSelector).call(el, selector)) {
                break;
            }
        } while (el = el.parentElement);

        return el;
    }
}
