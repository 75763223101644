import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MainmenuService } from './core/services/mainmenu.service';
import { BreadcrumbService } from './core/services/breadcrumb.service';
import { I18nService } from './core/services/i18n.service';
import { ReactNativeService} from './core/services/react-native.service';
import { WebsocketService } from './core/services/websocket.service';
import { UndoClickService } from './core/services/undo-click.service';
import { WsAgenda, WsOnlineStore, WsMeeting } from './core/models/ws';
import { AuthService } from './core/services/auth.service';
import { AgendaService } from './core/services/agenda.service';
import { environment } from 'src/environments/environment';
import { MeetingService } from './core/services/meeting.service';
import { Meeting } from './core/models/meeting.model';
import { User } from './core/models/user.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
    host: {
        '(window:message)': 'onReceiveMessage($event)',
        '(document:click)': 'onDocumentClick($event)',
    }
})
export class AppComponent implements OnDestroy, OnInit {
    readonly title: string = environment.application;
    readonly MEETING_LIVE_TIMEOUT: number = 60 * 5; // 5 min cache expire
    readonly BASE_URL: string = environment.scheme + '://' + environment.domain;

    showMenu: boolean = false;
    showNavigation: boolean = false;
    showQuorumConfirm: boolean = false;
    showVoteConfirm: boolean = false;

    breadcrumbNavigation: MenuItem[] = [];
    breadcrumbNavigationLast: MenuItem = null;
    meetingShow: boolean = false;
    meetingId: number = null;
    urlChange: boolean = false;
    user: User = new User;

    meetingLiveLastRequest: Date = null;
    meetingLive: Meeting = null;
    meetingLiveShow: boolean = false;
    meetingLiveAvailable: boolean = false;

    private subscriptions: Subscription[] = [];
    private profileSubscription: Subscription = null;
    private wsInitialized = false;
    private redirectTo: string = null;
    private page: string = null;

    constructor(
        private authService: AuthService,
        private reactNativeService: ReactNativeService,
        private i18nService: I18nService,
        private mainMenu: MainmenuService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta,
        private breadcrumbService: BreadcrumbService,
        private wsService: WebsocketService,
        private agendaService: AgendaService,
        private meetingService: MeetingService,
        private undoClickService: UndoClickService,
    ) { }

    ngOnInit() {
        this.i18nService.initPrimeNG();
        this.i18nService.initMoment();

        this.metaService.addTags([{
            property: 'og:site_name',
            content: environment.application
        }, {
            property: 'og:type',
            content: 'website'
        }, {
            property: 'og:description',
            content: 'Система за управление на онлайн заседания и гласувания към Общински съвет Варна',
        }, {
            property: 'og:image',
            content: this.BASE_URL + '/img/varco-default.png',
        }]);

        this.user = this.authService.user;
        let userId = null;
        const authSubscribe = this.authService.getStatus().subscribe(status => {
            if (status.isSigned) {
                this.initProfileReceive(this.authService.user?.id);
            } else {
                this.profileSubscription && this.profileSubscription.unsubscribe();
            }
            this.user = this.authService.user;
        });
        this.subscriptions.push(authSubscribe);

        const menuSubscribe = this.mainMenu.changes().subscribe(visible => {
            this.showMenu = visible;
        });
        this.subscriptions.push(menuSubscribe);

        const breadcrumbSubscribe = this.breadcrumbService.changes().subscribe((data: any) => {
            this.breadcrumbNavigation = data;
            setTimeout(() => this.breadcrumbNavigationLast = data.slice(-2, -1)[0] || null);
        });
        this.subscriptions.push(breadcrumbSubscribe);

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.reactNativeService.notifyUrlChange(event.url);
                this.moveOnTop();

                let current = this.route.root;
                while (current.children[0] !== undefined) {
                    current = current.children[0];
                }
                this.page = current.snapshot.data['page'] || null;

                if (!this.page || this.page !== 'live') {
                    this.showNavigation = true;
                    this.breadcrumbService.init(this.route.root);

                    if (!this.wsInitialized) {
                        this.initQuorumReceive();
                        this.initVoteReceive();
                        this.initVideoLiveStart();
                        this.initVideoLiveStop();
                        this.wsInitialized = true;
                    }

                    this.metaService.updateTag({
                        property: 'og:url',
                        content: this.BASE_URL + event.url
                    });
                } else {
                    this.titleService.setTitle(this.title);
                    this.metaService.updateTag({
                        property: 'og:title',
                        content: this.title
                    });
                }

                this.checkMeetingPage(this.page, current);
            });

        this.breadcrumbService.onTitleUpdate().subscribe(title => {
            title.unshift(this.title);
            this.titleService.setTitle(title.join(' - '));

            this.metaService.updateTag({
                property: 'og:title',
                content: title.join(' - ')
            });
        });
    }

    ngOnDestroy() {
        this.subscriptions.map(item => item && item.unsubscribe());
        this.profileSubscription && this.profileSubscription.unsubscribe();
    }

    moveOnTop(): void {
        if (typeof document !== 'undefined' && 'documentElement' in document && 'scrollTop' in document.documentElement) {
            document.documentElement.scrollTop = 0;
        }

        // Safari (and older Chrome) fallback
        if (typeof document !== 'undefined' && 'body' in document && 'scrollTop' in document.body) {
            document.body.scrollTop = 0;
        }
    }

    onReceiveMessage(data: any): void {
        this.reactNativeService.messageReceived(data);
    }

    onDocumentClick(event?: Event): void {
        this.undoClickService.setClick(event);
    }

    onQuorumConfirmed(answer: string): void {
        this.showQuorumConfirm = false;
        answer === 'yes' && this.router.navigateByUrl(this.redirectTo);
    }

    onVoteConfirmed(answer: string): void {
        this.showVoteConfirm = false;
        answer === 'yes' && this.router.navigateByUrl(this.redirectTo);
    }

    protected initQuorumReceive(): void {
        const wsOnlineSubscription = this.wsService
            .on('online.store')
            .subscribe((data: WsOnlineStore) => {
                if (this.authService.user.isValid()
                    && (!this.meetingShow || this.meetingId !== data.meeting)
                    && data.members.indexOf(this.authService.user.id) >= 0
                ) {
                    this.showQuorumConfirm = true;
                    this.redirectTo = `/committees/${data.committee}/meetings/${data?.meeting}?tab=quorum`;
                }
            });
        this.subscriptions.push(wsOnlineSubscription);
    }

    protected initVoteReceive(): void {
        let voteTimer: any = null;
        const wsVoteSubscription = this.wsService
            .on('agenda.vote')
            .subscribe((data: WsAgenda) => {
                if (!this.authService.user.isValid() || this.meetingShow || this.meetingId === data.meeting) {
                    return;
                }
                voteTimer && clearTimeout(voteTimer);
                voteTimer = setTimeout(() => {
                    this.agendaService.getItem(data?.id).subscribe(agenda => {
                        if (agenda.data.vote_allowed) {
                            this.showVoteConfirm = true;
                            this.redirectTo = `/committees/${data.committee}/meetings/${data?.meeting}?tab=voting`;
                        }
                        clearTimeout(voteTimer);
                    }, error => {
                        clearTimeout(voteTimer);
                    });
                }, 3000);
            });
        this.subscriptions.push(wsVoteSubscription);
    }

    protected initVideoLiveStart(): void {
        const wsOnlineSubscription = this.wsService
            .on('meeting.live.start')
            .subscribe((data: WsMeeting) => {
                if ((!this.page || ['live', 'meeting_show'].indexOf(this.page) === -1) && !this.meetingLiveShow) {
                    this.getLiveMeeting(data.id);
                }
            });
        this.subscriptions.push(wsOnlineSubscription);
    }

    protected initVideoLiveStop(): void {
        const wsOnlineSubscription = this.wsService
            .on('meeting.live.stop')
            .subscribe((data: WsMeeting) => {
                if (this.meetingLive?.id === data.id) {
                    this.meetingLive = null;
                    this.meetingLiveShow = false;
                    this.meetingLiveLastRequest = null;

                    if (!this.page || ['live', 'meeting_show'].indexOf(this.page) === -1) {
                        this.getLiveMeetings();
                    }
                }
            });
        this.subscriptions.push(wsOnlineSubscription);
    }

    protected initProfileReceive(userId): void {
        this.profileSubscription && this.profileSubscription.unsubscribe();
        this.profileSubscription = this.wsService
            .on('user.update', true, '.', 'user.' + userId)
            .subscribe((data: any) => {
                this.authService.user?.isValid() && this.authService.getActualProfileData().catch(e => {}).then();
            });
    }

    protected checkMeetingPage(page: string, current: ActivatedRoute): void {
        this.meetingShow = false;
        this.meetingId = 0;
        this.showQuorumConfirm = false;
        this.showVoteConfirm = false;
        this.redirectTo = '';

        if (page && page === 'meeting_show') {
            this.meetingShow = true;
            this.meetingId = parseInt(current.snapshot.params['id'] || '', 10) || 0;
        }

        if (!page || ['live', 'meeting_show'].indexOf(page) === -1) {
            this.getLiveMeetings();
        } else {
            this.meetingLiveShow = false;
        }
    }

    protected getLiveMeetings(): void {
        if (this.meetingLiveLastRequest && Date.now() < this.meetingLiveLastRequest.getTime() + 1000 * this.MEETING_LIVE_TIMEOUT) {
            return;
        }

        this.meetingLiveLastRequest = new Date();
        const subscription = this.meetingService.getList({
            status: 'active',
            limit: 1,
            sort: '-start',
            live_status: 'yes'
        }).subscribe(data => {
            this.getLiveMeeting(data.data[0]?.id);
        }, error => {
            this.meetingLiveShow = false;
        });

        this.subscriptions.push(subscription);
    }

    protected getLiveMeeting(id: number): void {
        const onError: () => void = () => {
            this.meetingLive = null;
            this.meetingLiveShow = false;
        }

        if (!id || id < 0) {
            onError();
            return;
        }

        const subscription = this.meetingService.getItem(id).subscribe(data => {
            this.meetingLive = data.data ? Object.assign(new Meeting, data.data) : null;
            this.meetingLiveAvailable = this.meetingLive.isVideoAvailable(this.user, true);
            this.meetingLiveShow = this.meetingLiveAvailable ? this.meetingLiveShow : false;
        }, error => onError());
        this.subscriptions.push(subscription);
    }

}
