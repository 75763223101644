import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiValidation } from 'src/app/core/services/api-validation.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    protected innerSubmit: boolean = null;

    @Input('loading') loading: boolean = false;
    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();


    @Output() submitChange: EventEmitter<boolean> = new EventEmitter();
    @Input('submit')
    set submit(value) {
        if (this.innerSubmit !== value) {
            this.innerSubmit = value;
            value && this.onSubmit();
        }
    }
    get submit() {
        return this.innerSubmit;
    }

    @Output() done: EventEmitter<any> = new EventEmitter();

    form: FormGroup = null;
    errors: any = {};
    messageError: string = '';

    constructor(
        private authService: AuthService,
        private apiValidation: ApiValidation,
    ) { }

    ngOnInit(): void {
        this.form = new FormGroup({
            email: new FormControl('', [
                Validators.required,
                Validators.minLength(4),
                Validators.email,
            ]),
            password: new FormControl('', [Validators.required])
        });
    }

    onSubmit(event?: any): void {
        setTimeout(() => this.submitChange.emit(false));

        if (this.loading) {
            return;
        }

        this.form.get('email').setValue((this.form.get('email').value || '').trim());
        this.form.get('password').setValue((this.form.get('password').value || ''));
        this.form.updateValueAndValidity();

        if (this.form.invalid) {
            this.errors = this.form.controls;
            this.messageError = 'Моля, проверете полетата';
            this.loadingChange.emit(false);
            return;
        } else {
            this.messageError = '';
        }

        this.loadingChange.emit(true);

        this.authService.login({
            email: this.form.get('email').value,
            password: this.form.get('password').value
        }).subscribe((data: any) => {
            this.done.emit();
        }, error => {
            this.loadingChange.emit(false);

            this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                this.messageError = data.first || "Невалидна парола или електронен адрес";
                this.errors = data.errors;
            });
        });
    }

}
