import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';

import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { EditorModule } from 'primeng/editor';
import { ListboxModule } from 'primeng/listbox';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SliderModule } from 'primeng/slider';

import { ListMeetingComponent } from './components/list-meeting/list-meeting.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { ListCommitteeComponent } from './components/list-committee/list-committee.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ModalAuthComponent } from './components/modal-auth/modal-auth.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MainSidebarComponent } from './components/main-sidebar/main-sidebar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ListingTableComponent } from './components/listing-table/listing-table.component';
import { SafePipe } from './pipes/safe.pipe';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { AlertComponent } from './components/alert/alert.component';
import { UploadComponent } from './components/upload/upload.component';
import { FormatTimerPipe } from './pipes/format-timer.pipe';
import { IsArrayPipe } from './pipes/is-array.pipe';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgotComponent } from './components/auth/forgot/forgot.component';
import { ResetComponent } from './components/auth/reset/reset.component';
import { InlineInputComponent } from './components/inline-input/inline-input.component';
import { InlineDateComponent } from './components/inline-date/inline-date.component';
import { CountInPipe } from './pipes/count-in.pipe';
import { InlineDropdownComponent } from './components/inline-dropdown/inline-dropdown.component';
import { ItemTemplateDirective } from './directives/item-template.directive';
import { InlineTextComponent } from './components/inline-text/inline-text.component';
import { ModalMeetingStartComponent } from './components/modal-meeting-start/modal-meeting-start.component';
import { ModalVoteInitComponent } from './components/modal-vote-init/modal-vote-init.component';
import { ModalVoteVotingComponent } from './components/modal-vote-voting/modal-vote-voting.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { ModalSortItemsComponent } from './components/modal-sort-items/modal-sort-items.component';
import { ModalSpeechComponent } from './components/modal-speech/modal-speech.component';
import { FilterPipe } from './pipes/filter.pipe';
import { QuorumComponent } from './components/quorum/quorum.component';
import { ModalVideoFormComponent } from './components/modal-video-form/modal-video-form.component';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { FormNavigationTopComponent } from './components/form-navigation-top/form-navigation-top.component';
import { FormNavigationBottomComponent } from './components/form-navigation-bottom/form-navigation-bottom.component';
import { FormNavigationComponent } from './components/form-navigation/form-navigation.component';
import { DateCardComponent } from './components/date-card/date-card.component';
import { ModalAgendaActionComponent } from './components/modal-agenda-action/modal-agenda-action.component';
import { MeetingVideoComponent } from './components/meeting-video/meeting-video.component';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { VoteResultComponent } from './components/vote-result/vote-result.component';
import { QuorumProgressComponent } from './components/quorum-progress/quorum-progress.component';
import { VoteMembersComponent } from './components/vote-members/vote-members.component';
import { CanPipe } from './pipes/can.pipe';
import { CanAnyPipe } from './pipes/can-any.pipe';
import { CanVotePipe } from './pipes/can-vote.pipe';
import { CanVoteProxyPipe } from './pipes/can-vote-proxy.pipe';
import { CanJoinPipe } from './pipes/can-join.pipe';
import { UserNamePipe } from './pipes/user-name.pipe';
import { UserFullNamePipe } from './pipes/user-full-name.pipe';
import { VoteCounterPipe } from './pipes/vote-counter.pipe';
import { VotePendingPipe } from './pipes/vote-pending.pipe';
import { UndoClickDirective } from './directives/undo-click.directive';
import { ModalResumeComponent } from './components/modal-resume/modal-resume.component';
import { ResizeObserverDirective } from './directives/resize-observer.directive';

@NgModule({
    declarations: [
        ListMeetingComponent,
        ListItemComponent,
        ListCommitteeComponent,
        AvatarComponent,
        ModalAuthComponent,
        MainHeaderComponent,
        MainSidebarComponent,
        SafePipe,
        ListingTableComponent,
        PaginationComponent,
        ModalConfirmComponent,
        AlertComponent,
        UploadComponent,
        FormatTimerPipe,
        IsArrayPipe,
        LoginComponent,
        ForgotComponent,
        ResetComponent,
        InlineInputComponent,
        InlineDateComponent,
        CountInPipe,
        InlineDropdownComponent,
        ItemTemplateDirective,
        InlineTextComponent,
        ModalMeetingStartComponent,
        ModalVoteInitComponent,
        ModalVoteVotingComponent,
        CountdownComponent,
        ModalSortItemsComponent,
        ModalSpeechComponent,
        FilterPipe,
        QuorumComponent,
        ModalVideoFormComponent,
        Nl2brPipe,
        FormNavigationTopComponent,
        FormNavigationBottomComponent,
        FormNavigationComponent,
        DateCardComponent,
        ModalAgendaActionComponent,
        MeetingVideoComponent,
        StripHtmlPipe,
        VoteResultComponent,
        QuorumProgressComponent,
        VoteMembersComponent,
        CanPipe,
        CanAnyPipe,
        CanVotePipe,
        CanVoteProxyPipe,
        CanJoinPipe,
        UserNamePipe,
        UserFullNamePipe,
        VoteCounterPipe,
        VotePendingPipe,
        UndoClickDirective,
        ModalResumeComponent,
        ResizeObserverDirective,
    ],
    imports: [
        CommonModule,
        RouterModule,
        DropdownModule,
        MultiSelectModule,
        BreadcrumbModule,
        SelectButtonModule,
        TabViewModule,
        DialogModule,
        MenuModule,
        ToastModule,
        EditorModule,
        ListboxModule,
        CheckboxModule,
        SkeletonModule,
        CalendarModule,
        InputNumberModule,
        ReactiveFormsModule,
        FormsModule,
        DragDropModule,
        InfiniteScrollModule,
        SliderModule,
    ],
    exports: [
        ListMeetingComponent,
        ListItemComponent,
        RouterModule,
        ListCommitteeComponent,
        AvatarComponent,
        RouterModule,
        DropdownModule,
        MultiSelectModule,
        BreadcrumbModule,
        SelectButtonModule,
        TabViewModule,
        DialogModule,
        MenuModule,
        ToastModule,
        EditorModule,
        ListboxModule,
        CheckboxModule,
        SkeletonModule,
        CalendarModule,
        InputNumberModule,
        ModalAuthComponent,
        ReactiveFormsModule,
        FormsModule,
        MainHeaderComponent,
        MainSidebarComponent,
        ListingTableComponent,
        PaginationComponent,
        ModalConfirmComponent,
        SafePipe,
        FormatTimerPipe,
        AlertComponent,
        UploadComponent,
        IsArrayPipe,
        LoginComponent,
        ForgotComponent,
        ResetComponent,
        InlineInputComponent,
        InlineDateComponent,
        InlineDropdownComponent,
        InlineTextComponent,
        CountInPipe,
        ItemTemplateDirective,
        ModalMeetingStartComponent,
        ModalVoteInitComponent,
        ModalVoteVotingComponent,
        CountdownComponent,
        ModalSortItemsComponent,
        DragDropModule,
        ModalSpeechComponent,
        FilterPipe,
        QuorumComponent,
        ModalVideoFormComponent,
        InfiniteScrollModule,
        Nl2brPipe,
        FormNavigationTopComponent,
        FormNavigationBottomComponent,
        FormNavigationComponent,
        DateCardComponent,
        ModalAgendaActionComponent,
        MeetingVideoComponent,
        StripHtmlPipe,
        VoteResultComponent,
        QuorumProgressComponent,
        VoteMembersComponent,
        CanPipe,
        CanAnyPipe,
        CanVotePipe,
        CanVoteProxyPipe,
        CanJoinPipe,
        UserNamePipe,
        UserFullNamePipe,
        VoteCounterPipe,
        VotePendingPipe,
        UndoClickDirective,
        ModalResumeComponent,
        ResizeObserverDirective,
    ]
})
export class SharedModule { }
