import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MeetingVideo } from 'src/app/core/models/meeting-video.model';
import { Meeting } from 'src/app/core/models/meeting.model';
import { User } from 'src/app/core/models/user.model';

@Component({
    selector: 'meeting-video',
    templateUrl: './meeting-video.component.html',
    styleUrls: ['./meeting-video.component.scss']
})
export class MeetingVideoComponent implements OnInit {
    protected innerIsPinned: boolean = false;
    protected innerMeeting: Meeting = null;
    protected innerLiveUrl: string = null;

    @ViewChild('mainVideo') mainVideo: ElementRef = null;

    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() videoAvailable: EventEmitter<boolean> = new EventEmitter();

    @Input() user: User = new User;
    @Input()
    set meeting(val: Meeting) {
        if (this.innerMeeting !== val) {
            this.innerMeeting = val;
            this.liveUrl = this.getMeetingUrl();
        }
    }
    get meeting(): Meeting {
        return this.innerMeeting;
    }
    @Input() active: boolean = false;
    @Input() showClose: boolean = false;

    @Input()
    set isPinned(val: boolean) {
        if (this.innerIsPinned !== val) {
            this.innerIsPinned = val;

            !this.innerIsPinned
                && this.mainVideo
                && this.mainVideo.nativeElement
                && this.renderer2.removeStyle(this.mainVideo.nativeElement, 'transform');
        }
    }
    get isPinned(): boolean {
        return this.innerIsPinned;
    }

    isPinnedMinimized: boolean = false;
    videoIndex: number = 0;
    isAvailableCheckTimer: any = null;
    isVideoAvailable: boolean = null;
    isZoomUrl: boolean = false;
    enterZoomUrl: boolean = false;
    embed = null;

    set liveUrl(val: string) {
        if (this.innerLiveUrl !== val) {
            this.innerLiveUrl = val;
            let isZoom = this.isZoomUrl;
            this.isZoomUrl = (this.innerLiveUrl || '')?.match(/^\/committees\/[\d]+\/meetings\/[\d]+\/live/i)?.length > 0;
            this.enterZoomUrl = isZoom !== this.isZoomUrl ? false : this.enterZoomUrl;
        }
    }
    get liveUrl(): string {
        return this.innerLiveUrl;
    }

    constructor(
        private renderer2: Renderer2,
    ) { }

    ngOnInit(): void {
        this.liveUrl = this.getMeetingUrl();
        this.isAvailableCheckTimer && clearTimeout(this.isAvailableCheckTimer);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isAvailableCheckTimer && clearTimeout(this.isAvailableCheckTimer);
        this.isAvailableCheckTimer = setTimeout(() => {
            const available: boolean = this.meeting.isVideoAvailable(this.user);

            if (this.isVideoAvailable !== available) {
                this.videoAvailable.emit(available);
                this.isVideoAvailable = available;
            }
        }, 20);
    }

    onClose(event: Event): boolean {
        event && event.preventDefault();
        this.close.emit(event);
        return false;
    }


    protected getMeetingUrl(): string {
        let url: string = null;
        let videos: MeetingVideo[] = (this.meeting?.videos || [])
            .filter(item => item.status === 'active');

        if (this.meeting?.schedule === 'live') {
            // Determine which video should be visible
            if (this.meeting?.room?.id && this.user?.id && (this.meeting?.canJoin(this.user) ||  this.user.canAnyOf(['meeting.update', 'agenda.voting']))) {
                url = `/committees/${this.meeting?.committee?.id || 0}/meetings/${this.meeting?.id || 0}/live`;
            } else if (this.meeting?.live_video_url?.length) {
                url = this.meeting?.live_video_url;
            }
        } else if (this.meeting?.schedule === 'past' && videos.length) {
            this.embed = videos[0].embed;
        }

        return url;
    }
}
