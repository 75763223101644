// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    application: 'VarCo',
    domain: 'localhost',
    scheme: 'http',
    port: 8000,
    webSockets: {
        broadcaster: 'pusher',
        key: '18d7b3ade5f8763f1ae81c43d8100075',
        wsHost: typeof window !== 'undefined' ? window.location?.hostname : 'localhost',
        wsPort: 6001,
        wssPort: 6001,
        forceTLS: false,
        disableStats: true,
    },
    pagination: {
        default: 25,
        committees: 15,
        councilors_card: 15,
        councilors_list: 35,
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
