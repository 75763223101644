import { UserBase } from './user-base.model';
import { UserRole } from "./user-role.model";

export class User extends UserBase {
    roles_count?: number;
    roles?: Array<UserRole>;
    permissions?: Array<string>;

    // not standard
    token?: string;

    /**
     * Check if user is guest
     */
    isUserGuest(): boolean {
        return !(this.name_first || '').length
            || !(this.name_last || '').length
            || !(this.id || 0)
            || !(this.token || '').length;
    }

    /**
     * Check if User data is invalid
     */
    isValid(): boolean {
        return this.hasToken()
            && (this.name_first || '').length > 0
            && (this.name_last || '').length > 0
            && (this.id || 0) > 0;
    }

    /**
     * Check if stored token is available
     */
    hasToken(): boolean {
        return (this.token || '').length > 0;
    }

    /**
     * Get current stored token
     */
    getToken(): string|null {
        return (this.token || '').length ? this.token : null;
    }

    /**
     * Set user token
     */
    setToken(token: string): this {
        if (token && typeof token === 'string' && token.length > 0) {
            this.token = token;
        }

        return this;
    }

    /**
     * Check if any of requested permissions are assigned to current user
     *
     * @param permissions List of permissions like 'profile.update', 'user.index', etc.
     * @return {boolean} True if all of requested permissions are given to the user
     */
    can(permissions: string[]|string): boolean {
        permissions = typeof permissions === 'string' ? [permissions] : permissions;

        for (const permission of permissions) {
            if (!(this.permissions || []).includes(permission)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Check if at least one of the requested permissions is assigned to current user
     *
     * @param permissions List of permissions like 'profile.update', 'user.index', etc.
     * @return {boolean} True if one (or more) of requested permissions is given to the user
     */
    canAnyOf(permissions: string[]|string): boolean {
        permissions = typeof permissions === 'string' ? [permissions] : permissions;

        for (const permission of permissions) {
            if ((this.permissions || []).includes(permission)) {
                return true;
            }
        }
        return false;
    }
}
