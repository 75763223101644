<div class="avatar" [ngClass]="{
    'medium': size === 'medium',
    'small': size === 'small',
    'big': size === 'big',
    'huge': size === 'huge'
}">
    <i class="ic-user" *ngIf="!user && !photo"></i>

    <small class="text-uppercase text-white initials" *ngIf="!photo && user?.initials?.length && !user?.photo?.length"> {{ user.initials }}</small>

    <img [src]="(photo || user?.photo) + (urlWidth && urlResize ? '?w=' + urlWidth : '')" *ngIf="photo || user?.photo?.length" class="h-100 w-100 " />

    <i *ngIf="enableRemoving && (photo?.length || user?.photo?.length)" class="fa fa-fw fa-times text-danger remove-photo" [title]="removingTitle" (click)="onRemoveClicked($event)"></i>

    <ng-content></ng-content>

    <span class="status rounded-circle align-middle p-2 mr-1 position-absolute"
          [class.bg-success]="status === true"
          [class.bg-danger]="status === false"
          [attr.title]="status === true ? 'Присъстващ' : status === false ? 'Отсъстващ' : null"
    ></span>
</div>
