<p-toast></p-toast>

<div [ngClass]="{
    'container-fluid': showNavigation,
    'show-menu': showNavigation,
    'no-scroll': showMenu && showNavigation
}">
    <div [class.row]="showNavigation">
        <div class="col-xl-2 col-lg-3 min-vh-100 position-relative sidebar" *ngIf="showNavigation">
            <main-sidebar [title]="title"
                          [hasLive]="meetingLiveAvailable"
                          (onLiveClick)="meetingLiveShow = true"></main-sidebar>
        </div>
        <div id="main" [ngClass]="showNavigation ? ['col-xl-10', 'col-lg-9'] : []">
            <main-header *ngIf="showNavigation"
                          [title]="title"
                          [breadcrumb]="breadcrumbNavigation"
            ></main-header>

            <main [class.py-2]="showNavigation">
                <a *ngIf="breadcrumbNavigationLast && !breadcrumbNavigationLast?.state?.skip"
                    [routerLink]="breadcrumbNavigationLast?.routerLink"
                    class="d-block my-3 d-block d-lg-none text-primary text-decoration-none"
                >
                    <i class="fas fa-fw fa-chevron-left"></i>
                    {{ breadcrumbNavigationLast?.label }}
                </a>

                <router-outlet></router-outlet>

                <modal-confirm *ngIf="showQuorumConfirm"
                            body="Провежда се проверка за кворум в заседание, в което членувате.<br />Желаете ли да отидете на страницата със заседанието?"
                            title="Проверка за кворум"
                            [skippable]="true"
                            (onAction)="onQuorumConfirmed($event)"
                ></modal-confirm>

                <modal-confirm *ngIf="showVoteConfirm"
                            body="Започна гласуване в заседание, в което членувате.<br /> Желаете ли да отидете на страницата със заседанието?"
                            title="Гласуване в заседание"
                            [skippable]="true"
                            (onAction)="onVoteConfirmed($event)"
                ></modal-confirm>

                <meeting-video *ngIf="meetingLiveShow"
                               [isPinned]="true"
                               [showClose]="true"
                               [meeting]="meetingLive"
                               [user]="user"
                               (close)="meetingLiveShow = false"
                ></meeting-video>
            </main>
        </div>
    </div>
</div>
