<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closeOnEscape]="skippable"
    [dismissableMask]="skippable"
    [closable]="closable"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw'}"
    [header]="title?.length ? title : '&nbsp;'"
>
    <span [innerHTML]="body" *ngIf="body"></span>

    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end align-items-center">
            <button *ngFor="let item of actions; let index = index"
                    type="button"
                    [title]="item?.tooltip || ''"
                    class="btn {{ item.btnClass || '' }} px-4"
                    (click)="onButtonClicked($event, item.action)"
            >
            <i class="{{ item.icon }}" *ngIf="item?.icon?.length"></i>
            {{ item.name || '' }}
            </button>
        </div>
    </ng-template>
</p-dialog>
