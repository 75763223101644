import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

export interface ModalConfirmComponentAction {
    name?: string;
    action?: string;
    btnClass?: string;
    tooltip?: string;
    icon?: string;
};

@Component({
    selector: 'modal-confirm',
    templateUrl: './modal-confirm.component.html',
    styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit, OnDestroy {
    @Output() onAction: EventEmitter<string> = new EventEmitter();

    @Input() skippable: boolean = true;
    @Input() closable: boolean = true;
    @Input() title: string = '';
    @Input() body: string = 'Наистина ли желаете да продължите с действието?';
    @Input() actions: ModalConfirmComponentAction[] = [{
        name: 'Да', action: 'yes', btnClass: 'btn-primary', tooltip: 'Съгласяване', icon: 'fa fa-fw fa-check'
    }, {
        name: 'Не', action: 'no', btnClass: 'btn-secondary', tooltip: 'Отказ', icon: 'fa fa-fw fa-times'
    }];

    constructor() { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    onCloseAction(e: any): void {
        if (this.skippable) {
            this.onAction.emit('close');
        }
    }

    onButtonClicked(event: Event, action: string): void {
        event.preventDefault();
        this.onAction.emit(action);
    }

}
