import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Agenda, AgendaAgendaStatus, AgendaStatus, AgendaType } from './../models/agenda.model';
import { AgendaMultipleDelete } from './../models/agenda-multiple-delete.model';
import { AgendaMultiple } from './../models/agenda-multiple.model';
import { Paginate } from './../models/paginate.model';
import { AgendaVoteDetails } from './../models/agenda-vote-details.model';
import { AgendaVote, AgendaVoteStatus } from './../models/agenda-vote.model';
import { AgendaAttachment } from './../models/agenda-attachment.model';
import { Observable } from 'rxjs';
import { FilterSort } from '../models/filter-sort.model';
import { AgendaOnline, AgendaOnlineStatus } from '../models/agenda-online.model';
import { AgendaComment } from './../models/agenda-comment.model';
import { AgendaAttachmentMultiple } from './../models/agenda-attachment-multiple.model';
import { AgendaVoteMultiple } from './../models/agenda-vote-multiple.model';

export type AgendaVoteResult = ('pending'|'approved'|'rejected');

export interface AgendaServiceFilter {
    q?: string;
    sort?: string|FilterSort[];
    page?: number;
    limit?: number;
    status?: string|string[];
    meeting?: number;
    type?: AgendaType
};

export interface AgendaServiceAttachmentFilter {
    q?: string;
    sort?: string|FilterSort[];
    page?: number;
    limit?: number;
    status?: string|string[];
};

export interface AgendaServiceInputAttachment {
    title?: string;
    description?: string;
    path?: string;
    mime?: string;
    size?: number;
    position?: number;
    status?: ('active'|'inactive');
}

export interface AgendaServiceCommentFilter {
    sort?: string|FilterSort[];
    page?: number;
    limit?: number;
    status?: string|string[];
};

export interface AgendaServiceInputComment {
    description?: string;
    status?: ('active'|'inactive');
};

export interface AgendaServiceInput {
    meeting?: number;
    title?: string;
    description?: string;
    quorum_duration?: number;
    quorum_start_at?: string;
    quorum_end_at?: number;
    vote_title?: string;
    vote_duration?: number;
    vote_start_at?: string;
    vote_end_at?: number;
    vote_result?: 'auto' & AgendaVoteResult;
    position?: number;
    agenda_status?: AgendaAgendaStatus;
    status?: AgendaStatus;
    type?: AgendaType;
};

@Injectable({
    providedIn: 'root'
})
export class AgendaService {

    constructor(
        private api: ApiService,
    ) { }

    /**
     * Get Agendas list
     *
     * @param filter
     * @returns
     */
    getList(filter?: AgendaServiceFilter): Observable<Paginate<Agenda>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort));
        filter && filter?.status && Array.isArray(filter.status) && (filter['status'] = filter.status.join(','));

        return this.api.get<Paginate<Agenda>>("/agendas", {params: filter});
    }

    /**
     * Get single Agenda
     * @param id
     * @returns
     */
    getItem(id: number): Observable<{data: Agenda}> {
        return this.api.get<{data: Agenda}>("/agendas/" + id);
    }

    /**
     * Add new Agenda
     * @param data
     * @returns
     */
    add(data: AgendaServiceInput): Observable<{data: Agenda}> {
        return this.api.post("/agendas", data);
    }

    /**
     * Edit existing Agenda
     * @param id
     * @param data
     * @returns
     */
    edit(id: number, data: AgendaServiceInput): Observable<{data: Agenda}> {
        return this.api.post("/agendas/" + id, data);
    }

    /**
     * Edit existing Multiple Agenda
     * @param data
     * @returns
     */
    editMultiple(data: Array<{id: number} & AgendaServiceInput>): Observable<{data: AgendaMultiple[]}> {
        return this.api.patch("/agendas", {data});
    }

    /**
     * Edit position of Multiple Agenda
     * @param data
     * @returns
     */
    sort(data: Array<{id: number, position: number}>): Observable<{data: AgendaMultiple[]}> {
        return this.api.patch("/agendas/sort", {data});
    }

    /**
     * Delete an agenda
     * @param id
     * @returns
     */
    delete(id: number): Observable<{}> {
        return this.api.delete("/agendas/" + id);
    }

    /**
     * Delete multiple agendas at once
     * @param ids
     * @returns
     */
    deleteMulti(ids: number[]): Observable<{data: AgendaMultipleDelete[]}> {
        return this.api.delete("/agendas?ids=" + ids.join(','));
    }

    /**
     * Get votes list and/or summary only to specific agenda
     * @param id
     */
    votes(id: number): Observable<{data: AgendaVoteDetails}> {
        return this.api.get<{data: AgendaVoteDetails}>("/agendas/" + id + "/votes");
    }

    /**
     * Performs a new vote to a single agenda
     *
     * @param id
     * @param vote Status of the vote
     * @param user User when voting is from proxy
     */
    voting(id: number, vote: AgendaVoteStatus, user?: number): Observable<{data: AgendaVote}> {
        return this.api.post<{data: AgendaVote}>("/agendas/" + id + "/votes", {vote, user});
    }

    /**
     * Performs a new vote to a multiple agendas
     *
     * @param ids An array of agendas from same meeting
     * @param vote Status of the vote
     * @param user User when voting is from proxy
     */
    votingMultiple(ids: number[], vote: AgendaVoteStatus, user?: number): Observable<{data: AgendaVoteMultiple[]}> {
        return this.api.post<{data: AgendaVote}>("/agendas/votes", {vote, user, ids});
    }

    /**
     * Get list of attachments within single Agenda
     * @param id
     * @param filter
     * @returns
     */
    attachmentGetList(id: number, filter?: AgendaServiceAttachmentFilter): Observable<Paginate<AgendaAttachment>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort));
        filter && filter?.status && Array.isArray(filter.status) && (filter['status'] = filter.status.join(','));

        return this.api.get('/agendas/' + id + '/attachments', {params: filter});
    }

    /**
     * Get single attachment from Agenda
     * @param id
     * @param attachId
     * @returns
     */
    attachmentGetItem(id: number, attachId: number): Observable<{data: AgendaAttachment}> {
        return this.api.get('/agendas/' + id + '/attachments/' + attachId);
    }

    /**
     * Edit position of Multiple attachments within agenda
     * @param id
     * @param data
     * @returns
     */
    attachmentSort(id: number, data: Array<{id: number, position: number}>): Observable<{data: AgendaAttachmentMultiple[]}> {
        return this.api.patch('/agendas/' + id + '/attachments/sort', {data});
    }

    /**
     * Add new attachment file to Agenda
     * @param id
     * @param data
     * @returns
     */
    attachmentAdd(id: number, data: AgendaServiceInputAttachment): Observable<{data: AgendaAttachment}> {
        return this.api.post('/agendas/' + id + '/attachments', data);
    }

    /**
     * Edit existing attachment file in Agenda
     * @param id
     * @param attachId
     * @param data
     * @returns
     */
    attachmentEdit(id: number, attachId: number, data: AgendaServiceInputAttachment): Observable<{data: AgendaAttachment}> {
        return this.api.post('/agendas/' + id + '/attachments/' + attachId, data);
    }

    /**
     * Delete an existing attachment file within Agenda
     * @param id
     * @param attachId
     * @returns
     */
    attachmentDelete(id: number, attachId: number): Observable<{}> {
        return this.api.delete('/agendas/' + id + '/attachments/' + attachId);
    }

    /**
     * Get list of online users for Agenda
     * @param id
     * @returns
     */
    onlineList(id: number): Observable<{data: AgendaOnline[]}> {
        return this.api.get('/agendas/' + id + '/online');
    }

    /**
     * Update status or init online users list in Agenda
     * @param id
     * @param input
     * @returns
     */
    onlineUpdate(id: number, input: {status?: AgendaOnlineStatus, init?: boolean}): Observable<{}> {
        return this.api.post('/agendas/' + id + '/online', input);
    }

    /**
     * Get list of comments for single Agenda
     * @param id
     * @param filter
     * @returns
     */
    commentGetList(id: number, filter?: AgendaServiceCommentFilter): Observable<{data: AgendaComment}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort));
        filter && filter?.status && Array.isArray(filter.status) && (filter['status'] = filter.status.join(','));

        return this.api.get('/agendas/' + id + '/comments', {params: filter});
    }

    /**
     * Get single attachment from Agenda
     * @param id
     * @param commentId
     * @returns
     */
    commentGetItem(id: number, commentId: number): Observable<{data: AgendaComment}> {
        return this.api.get('/agendas/' + id + '/comments/' + commentId);
    }

    /**
     * Add a new comment to Agenda
     * @param id
     * @param data
     * @returns
     */
    commentAdd(id: number, data: AgendaServiceInputComment): Observable<{data: AgendaComment}> {
        return this.api.post('/agendas/' + id + '/comments', data);
    }

    /**
     * Edit existing comment in Agenda
     * @param id
     * @param commentId
     * @param data
     * @returns
     */
    commentEdit(id: number, commentId: number, data: AgendaServiceInputComment): Observable<{data: AgendaComment}> {
        return this.api.post('/agendas/' + id + '/comments/' + commentId, data);
    }

    /**
     * Delete an existing comment within Agenda
     * @param id
     * @param commentId
     * @returns
     */
    commentDelete(id: number, commentId: number): Observable<{}> {
        return this.api.delete('/agendas/' + id + '/comments/' + commentId);
    }
}
