import { AbstractControl, ValidatorFn } from '@angular/forms';

interface ReturnValue {
    [key: string]: {
        requiredValue: any,
        actualValue: any,
    }
};

/**
 * Check for control's value equal to another control's value
 */
export const equalTo = (equalTo: AbstractControl): ValidatorFn => {
    let subscribe: boolean = false;

    return (control: AbstractControl): ReturnValue => {
        if (!subscribe) {
            subscribe = true;
            equalTo.valueChanges.subscribe(() => {
                control.updateValueAndValidity();
            });
        }

        let v: string = control.value;
        return equalTo.value === v ? null
            : { equalto: {
                    requiredValue: equalTo.value,
                    actualValue: control.value
            }};
    };
};
