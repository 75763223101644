
<aside id="sidebar" [class.active]="showMenu">
    <a routerLink="/" id="logo" class="logo d-flex align-items-center justify-content-center p-2" [title]="title">
        <img [src]="logoPrefix + 'assets/img/logo.svg'" class="h-100 mw-100" />
    </a>
    <div class="col-12 menu-wrapper" undoClick (onUndoClick)="menuClickOutside($event)">
        <nav (click)="hideMenu($event)">
            <ul>

                <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/"><i class="ic-home"></i> Начало</a></li>
                <li><a routerLinkActive="active" routerLink="/committees/municipality"><i class="ic-people-3rd"></i> Съвет</a></li>
                <li><a [class.active]="isCommitteesPage" routerLink="/committees"><i class="ic-people-2nd"></i> Комисии</a></li>
                <li><a routerLinkActive="active" routerLink="/councilors"><i class="ic-business-user"></i> Съветници</a></li>
                <li><a routerLinkActive="active" routerLink="/mandates" *ngIf="user | can:'mandate.index'"><i class="ic-slide"></i> Мандати</a></li>
                <li><a routerLinkActive="active" routerLink="/rooms" *ngIf="user | can:'room.index'"><i class="ic-play"></i> Zoom.US стаи</a></li>
                <li><a routerLinkActive="active" routerLink="/users" *ngIf="user | can:'user.index'"><i class="ic-user"></i> Потребители</a></li>
                <li><a routerLinkActive="active" routerLink="/political-groups" *ngIf="user | can:'party.index'"><i class="far fa-handshake"></i> Групи</a></li>
                <li *ngIf="hasLive" class="text-center border-top pt-2">
                    <a  class="badge badge-pill bg-white py-2 px-3 d-inline-block w-auto border" href="#" (click)="onLive($event)">
                        <i class="fas fa-fw fa-play pulse text-danger"></i>
                        Заседание на живо
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <footer class="text-center small">
        <p class="my-1">{{ title }} &copy; 2021-2024</p>
        <p class="my-1">Създадено от <a href="https://advanceacademy.bg/" target="_blank">AdvanceAcademy.bg</a></p>
    </footer>
</aside>
