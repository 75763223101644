import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private primeConfig: PrimeNGConfig,
    ) { }

    initPrimeNG() {

        // Setup i18n support for PrimeNG (globally)
        this.primeConfig.setTranslation({
            dayNames: [
                "неделя",
                "понеделник",
                "вторник",
                "сряда",
                "четвъртък",
                "петък",
                "събота"
            ],
            dayNamesShort: [
                "нед",
                "пон",
                "вто",
                "сря",
                "чет",
                "пет",
                "съб"
            ],
            dayNamesMin: [
               "нд",
               "пн",
               "вт",
               "ср",
               "чт",
               "пт",
               "сб"
            ],
            monthNames: [
                "януари",
                "февруари",
                "март",
                "април",
                "май",
                "юни",
                "юли",
                "август",
                "септември",
                "октомври",
                "ноември",
                "декември"
            ],
            monthNamesShort: [
                "яну",
                "фев",
                "мар",
                "апр",
                "май",
                "юни",
                "юли",
                "авг",
                "сеп",
                "окт",
                "ное",
                "дек"
            ],
            today: "Днес",
            clear: "Изчисти",
            weekHeader: "С",
        });
    }

    initMoment() {
        moment.locale(this.locale);
    }
}
