<p-dialog
    [visible]="mode === 'auth'"
    (visibleChange)="onHidePopup($event, 'auth')"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    [draggable]="false"
    [modal]="true"
    contentStyleClass="flex-shrink-0"
    maskStyleClass="overflow-auto"
    [style]="{width: '500px'}"
    header="Вписване"
>
    <auth-login [(submit)]="loginSubmit" [(loading)]="loading" (done)="close($event)"></auth-login>

    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-start align-items-center">
            <button type="button" class="btn btn-primary" (click)="loginSubmit = true" [attr.disabled]="loading ? true : null">
                <i *ngIf="!loading" class="fa fa-fw fa-check"></i>
                <i *ngIf="loading" class="fas fa-fw fa-spinner fa-pulse"></i>
                Вход
            </button>
            <a class="ml-auto" (click)="mode = 'forgot'">Забравена парола?</a>
        </div>
    </ng-template>
</p-dialog>


<p-dialog
    [visible]="mode === 'forgot'"
    (visibleChange)="onHidePopup($event, 'forgot')"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    [draggable]="false"
    [modal]="true"
    contentStyleClass="flex-shrink-0"
    maskStyleClass="overflow-auto"
    [style]="{width: '500px'}"
    header="Забравена парола?"
>
    <auth-forgot [(submit)]="forgotSubmit" [(loading)]="loading" (done)="forgotSent = true"></auth-forgot>

    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-start align-items-center">
            <button *ngIf="!forgotSent"
                type="button" class="btn btn-primary"
                (click)="forgotSubmit = true"
                [attr.disabled]="loading ? true : null"
            >
                <i *ngIf="!loading" class="fa fa-fw fa-paper-plane"></i>
                <i *ngIf="loading" class="fas fa-fw fa-spinner fa-pulse"></i>
                Изпращане
            </button>
            <a class="ml-auto" (click)="mode = 'auth'" *ngIf="!loading">Вписване?</a>
        </div>
    </ng-template>
</p-dialog>


<p-dialog
    [visible]="mode === 'reset'"
    (visibleChange)="onHidePopup($event, 'reset')"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    [draggable]="false"
    [modal]="true"
    contentStyleClass="flex-shrink-0"
    maskStyleClass="overflow-auto"
    [style]="{width: '500px'}"
    header="Възстановяване на парола"
>
    <auth-reset [(submit)]="resetForm"
                [(loading)]="loading"
                [email]="email"
                [token]="token"
                (error)="resetError = true"
                (done)="resetSent = true"></auth-reset>

    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-start align-items-center">
            <button *ngIf="!resetSent"
                type="button" class="btn btn-primary"
                (click)="resetForm = true"
                [attr.disabled]="loading ? true : null"
            >
                <i *ngIf="!loading" class="fa fa-fw fa-paper-plane"></i>
                <i *ngIf="loading" class="fas fa-fw fa-spinner fa-pulse"></i>
                Изпращане
            </button>

            <div class="ml-auto">
                <a class="mr-4" (click)="mode = 'forgot'; resetError = false" *ngIf="!loading && !resetSent && resetError">Нов опит?</a>
                <a (click)="mode = 'auth'" *ngIf="!loading">Вписване?</a>
            </div>
        </div>
    </ng-template>
</p-dialog>
