import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';


@Pipe({name: 'safe'})
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    /**
     * Make an URL safety
     *
     * @param url Url to be checked
     * @returns {SafeHtml|SafeStyle|SafeScript|SafeUrl|SafeResourceUrl} Trusted resource
     */
    transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resource':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            case 'html':
            default:
                return this.sanitizer.bypassSecurityTrustHtml(value);
        }
    }
}
