import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiValidation } from 'src/app/core/services/api-validation.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ExtendedValidators } from 'src/app/shared/directives/validators';

@Component({
    selector: 'auth-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
    protected innerSubmit: boolean = null;

    @Input('token') token: string = null;
    @Input('email') email: string = null;

    @Input('loading') loading: boolean = false;
    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();

    @Output() submitChange: EventEmitter<boolean> = new EventEmitter();
    @Input('submit')
    set submit(value) {
        if (this.innerSubmit !== value) {
            this.innerSubmit = value;
            value && this.onSubmit();
        }
    }
    get submit() {
        return this.innerSubmit;
    }

    @Output() done: EventEmitter<any> = new EventEmitter();
    @Output() error: EventEmitter<any> = new EventEmitter();

    form: FormGroup = null;
    errors: any = {};
    messageError: string = '';
    messageSuccess: string = '';

    constructor(
        private authService: AuthService,
        private apiValidation: ApiValidation,
    ) { }

    ngOnInit(): void {
        const resetPasswordControl = new FormControl('', [
            Validators.required,
            Validators.minLength(8)
        ]);

        this.form = new FormGroup({
            password: resetPasswordControl,
            passwordConfirm: new FormControl('', [ExtendedValidators.equalTo(resetPasswordControl)]),
        });
    }

    onSubmit(event?: any): void {
        setTimeout(() => this.submitChange.emit(false));

        if (this.loading) {
            return;
        }

        this.form.updateValueAndValidity();

        if (this.form.invalid) {
            this.errors = this.form.controls;
            this.messageError = 'Моля, проверете полетата';
            this.loadingChange.emit(false);
            this.error.emit(true);
            return;
        } else {
            this.error.emit(false);
            this.messageError = '';
        }

        this.loadingChange.emit(true);

        this.authService.reset({
            email: this.email || '',
            password: this.form.get('password').value,
            token: this.token || '',
        }).subscribe((data: any) => {
            this.loadingChange.emit(false);
            this.messageSuccess = "Успешно сметихте своята парола!";
            this.done.emit(true);
        }, error => {
            this.loadingChange.emit(false);
            this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                this.messageError = "Неуспешна смяна! Вероятно линкът вече е неактуален!";
                this.errors = data.errors;
                this.error.emit(true);
            });
        });
    }

}
