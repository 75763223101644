<header>
    <div class="col-12 h-100"><!-- Don't add a .row - it's on purpose! -->
        <div class="row h-100">
            <div class="col-7 d-flex align-items-center h-100">
                <button class="btn mr-3 menu-toggle d-block d-lg-none" type="button" data-toggle="collapse" [class.active]="showMenu" (click)="toggleMenu($event)">
                    <span class="fa fa-fw fa-bars"></span>
                </button>

                <a routerLink="/" class="logo d-flex align-items-center justify-content-left d-block d-lg-none p-3 p-md-2 h-100 w-100">
                    <img [src]="logoPrefix + 'assets/img/logo.svg'" class="h-100" />
                </a>
                <div class="welcome d-none d-lg-flex">
                    <ng-container *ngIf="!breadcrumb?.length">
                        Добре дошли!
                    </ng-container>

                    <p-breadcrumb *ngIf="breadcrumb?.length"
                        [home]="{routerLink: '/', icon: 'fa fa-fw fa-home'}"
                        [model]="breadcrumb"
                    ></p-breadcrumb>
                </div>
            </div>
            <div class="col-5 d-flex align-items-center justify-content-end h-100 text-right">
                <a class="profile d-none d-lg-block" (click)="onLogin($event)" *ngIf="!isUserValid">
                    <avatar></avatar>
                    <div class="d-flex flex-column justify-content-center">
                        <span class="name d-none d-sm-block">Вписване</span>
                        <span class="type d-none d-sm-block">Гост</span>
                    </div>
                </a>
                <a routerLink="/auth/login" class="profile d-block d-lg-none" *ngIf="!isUserValid">
                    <avatar></avatar>
                    <div class="d-flex flex-column justify-content-center">
                        <span class="name d-none d-sm-block">Вписване</span>
                        <span class="type d-none d-sm-block">Гост</span>
                    </div>
                </a>

                <div class="dropdown mr-4" *ngIf="isUserValid">
                    <button class="btn btn-outline-secondary dropdown-toggle d-inline-flex align-items-center " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        (click)="showProfileMenu = !showProfileMenu"
                        undoClick (onUndoClick)="showProfileMenu = false"
                    >
                        <div class="profile d-inline-flex align-items-center justify-content-end">
                            <avatar [user]="user" class="d-block"></avatar>

                            <div class="d-flex flex-column justify-content-center">
                                <span class="name d-none d-sm-inline-block">{{ user | userFullName }}</span>
                            </div>
                        </div>
                    </button>

                    <div class="dropdown-menu dropdown-menu-right" [class.show]="showProfileMenu">
                        <a class="dropdown-item" routerLink="/profile"><i class="far fa-fw fa-folder"></i> Профил</a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item" (click)="onLogout($event)"><i class="fas fa-fw fa-sign-out-alt"></i> Излизане</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<modal-auth class="d-none d-lg-block" *ngIf="showAuth"
    (close)="onAuthClose($event)"
    [token]="authToken"
    [email]="authEmail"
    [mode]="authMode"
></modal-auth>
