import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

const api = () => `${environment.scheme}://${environment.domain}${environment.port ? ':' + environment.port : ''}/api/`;

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    protected readonly API: string = api() || 'http://localhost:4000/api/';
    protected token: string = null;

    constructor(
        private http: HttpClient,
    ) { }

    /**
     * Make any type of request
     * @param request Type of the request, i.e. GET, POST, DELETE, etc
     * @param node API node based on default API URL
     * @param options Possible custom options, including "body" and/or "headers"
     */
    request<T>(request: string, node: string, options?: any): Observable<any> {

        return this.http.request<T>(request, this.buildFullUrl(node), this.getOptions(options));
    }

    /**
     * Make a GET request
     * @param node API node based on default API URL
     * @param options Possible custom options, eg. "headers"
     */
    get<T>(node: string, options?: any): Observable<any> {
        return this.http.get<T>(this.buildFullUrl(node), this.getOptions(options));
    }

    /**
     * Make a POST request
     * @param node API node based on default API URL
     * @param data Data to be send to the server
     * @param options Possible custom options, eg. "headers"
     */
    post<T>(node: string, data?: any, options?: any): Observable<any> {
        return this.http.post<T>(this.buildFullUrl(node), data, this.getOptions(options));
    }

    /**
     * Make a DELETE request
     * @param node API node based on default API URL
     * @param options Possible custom options, eg. "headers"
     */
    delete<T>(node: string, options?: any): Observable<any> {
        return this.http.delete<T>(this.buildFullUrl(node), this.getOptions(options));
    }

    /**
     * Make a HEAD request
     * @param node API node based on default API URL
     * @param options Possible custom options, eg. "headers"
     */
    head<T>(node: string, options?: any): Observable<any> {
        return this.http.head<T>(this.buildFullUrl(node), this.getOptions(options));
    }

    /**
     * Make a PUT request
     * @param node API node based on default API URL
     * @param data Data to be send to the server
     * @param options Possible custom options, eg. "headers"
     */
    put<T>(node: string, data?: any, options?: any): Observable<any> {
        return this.http.put<T>(this.buildFullUrl(node), data, this.getOptions(options));
    }

    /**
     * Make a PATCH request
     * @param node API node based on default API URL
     * @param data Data to be send to the server
     * @param options Possible custom options, eg. "headers"
     */
    patch<T>(node: string, data?: any, options?: any): Observable<any> {
        return this.http.patch<T>(this.buildFullUrl(node), data, this.getOptions(options));
    }

    /**
     * Build a full URL to the API node
     * @param node API node
     */
    buildFullUrl(node: string): string {
        return this.API.replace(/\/+$/, '') + '/' + node.replace(/^\/+/, '');
    }

    /**
     * Update current token
     * @param token
     */
    updateToken(token: string): void {
        if (token && typeof token === 'string') {
            this.token = token;
        }
    }

    hasToken(): boolean {
        return this.token && this.token.length > 0;
    }

    getToken(): string|null {
        return this.hasToken() ? this.token : null;
    }

    getOptions(options: any): any {
        let headers = new HttpHeaders();

        if (this.token && this.token.length) {
            headers = new HttpHeaders({
                'X-Auth-Token': `Bearer ${this.token}`
                // Authorization: `Bearer ${this.token}`
            });
        }

        return this.mergeObject({
            ...{headers},
        }, options ? options : {});
    }

    public getSortParams(sort: Array<{field?: string, direction?: ('asc'|'desc')}>): string {
        let output = [];

        for (let item of sort) {
            output.push((item?.direction === 'asc' ? '+' : '-') + (item?.field || ''));
        }
        return output.join(',');
    }

    protected mergeObject(target, ...sources) {
        if (!sources.length) {
            return target;
        }
        const source = sources.shift();

        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, {[key]: {}});
                    }
                    this.mergeObject(target[key], source[key]);
                } else {
                    Object.assign(target, {[key]: source[key]});
                }
            }
        }

        return this.mergeObject(target, ...sources);
    }

    protected isObject(item): boolean {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }
}
