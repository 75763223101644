import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UndoClickService } from 'src/app/core/services/undo-click.service';

@Directive({
    selector: '[undoClick]',
})
export class UndoClickDirective {
    @Output('onUndoClick') onUndoClick: EventEmitter<Event> = new EventEmitter();

    protected eventSubscription: Subscription = null;

    constructor(
        private elementRef: ElementRef,
        private undoClickService: UndoClickService,
    ) { }

    ngOnInit(): void {
        this.eventSubscription = this.undoClickService.onClickEvent().subscribe(event => {
            if (!event?.target || !this.elementRef?.nativeElement) {
                return;
            }

            if (!this.elementRef.nativeElement.contains(event.target)) {
                this.onUndoClick.emit(event);
            }
        });
    }

    ngOnDestroy(): void {
        this.eventSubscription && this.eventSubscription.unsubscribe();
    }
}
