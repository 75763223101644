import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MainmenuService {
    protected innerVisible: boolean = false;

    protected set visible(value: boolean) {
        if (this.innerVisible !== value) {
            this.innerVisible = value;
            this.menuChange.next(value);
        }
    }

    protected get visible() {
        return this.innerVisible;
    }

    protected menuChange: BehaviorSubject<boolean> = new BehaviorSubject(this.innerVisible);

    constructor() { }

    toggle() {
        this.visible = !this.visible;
    }

    hide() {
        this.visible = false;
    }

    show() {
        this.visible = true;
    }

    changes(): Observable<boolean> {
        return this.menuChange.pipe(share());
    }
}
