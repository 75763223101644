
<alert type="error" [message]="messageError" (remove)="messageError = ''"></alert>
<alert type="success" [message]="messageSuccess" (remove)="messageSuccess = ''"></alert>

<p *ngIf="!messageSuccess?.length">Моля, въведете своя електронен адрес (email)!</p>
<p *ngIf="!messageSuccess?.length">Ще Ви бъде изпратено писмо с инструкции как да си възстановите паролата.</p>

<form class="my-3" (submit)="onSubmit($event)" [formGroup]="form" autocomplete="off">
    <div class="form-group row">
        <label for="inputForgotEmail" class="col-xs-12 col-sm-4 col-form-label">E-mail</label>
        <div class="col-xs-12 col-sm-8">
            <input
                type="email"
                class="form-control"
                id="inputForgotEmail"
                formControlName="email"
                [attr.disabled]="loading || messageSuccess?.length ? true : null"
                [class.is-invalid]="errors?.email?.status === 'INVALID'"
                autofocus
            />

            <div class="invalid-feedback" *ngIf="errors?.email?.errors?.required">Полето е задължително</div>
            <div class="invalid-feedback" *ngIf="errors?.email?.errors?.minlength && !errors?.name?.errors?.required">Полето е по-малко от 4 символа</div>
            <div class="invalid-feedback" *ngIf="errors?.email?.errors?.email && !errors?.email?.errors?.minlength">Въведете валиден e-mail адрес</div>
            <div class="invalid-feedback" *ngIf="errors?.email?.errors?.any">{{ errors?.email?.errors?.any }}</div>
        </div>
    </div>
</form>
