import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { AuthService } from './../../../core/services/auth.service';
import { ApiValidation } from './../../../core/services/api-validation.service';

type Mode = ('auth' | 'forgot' | 'reset');

@Component({
    selector: 'modal-auth',
    templateUrl: './modal-auth.component.html',
    styleUrls: ['./modal-auth.component.scss']
})
export class ModalAuthComponent implements OnInit {
    @Output('close') onClose: EventEmitter<void> = new EventEmitter;
    @Input('token') token: string = null;
    @Input('email') email: string = null;
    @Input('mode') mode: Mode = 'auth';

    loading: boolean = false;

    loginSubmit: boolean = false;

    forgotSubmit: boolean = false;
    forgotSent: boolean = false;

    resetForm: boolean = false;
    resetSent: boolean = false;
    resetError: boolean = false;

    constructor(
        private authService: AuthService,
        private apiValidation: ApiValidation
    ) { }

    close(event: any): void {
        this.onClose.emit();
    }

    ngOnInit(): void {

    }

    onHidePopup(event?: any, mode: Mode = 'auth'): void {
        if (this.mode === mode) {
            this.onClose.emit();
        }
    }
}
