import { MeetingAttachment } from "./meeting-attachment.model";
import { Agenda } from "./agenda.model";
import { Committee } from "./committee.model";
import { RoomConnection } from "./room-connection.model";
import { User } from "./user.model";
import { MeetingVideo } from "./meeting-video.model";
import { MeetingDate } from './meeting-date.model';
import { UserBase } from "./user-base.model";

export class Meeting {
    id?: number;
    title?: string;
    description?: string;
    room?: RoomConnection;
    live_video_url?: string;
    live_status?: string;
    start_at?: string;
    end_at?: string;
    date?: MeetingDate;
    position?: string;
    agendas?: Agenda[];
    procedures?: Agenda[];
    committee?: Committee;
    attachments?: MeetingAttachment[];
    schedule?: ("pending"|"live"|"past");
    status?: ("active"|"inactive");
    videos?: MeetingVideo[];

    canJoin(user: User): boolean {
        return user.isValid()
            && this.id > 0
            && (
                (this.committee?.users || []).filter(item => item.id === user.id).length > 0
                || this.committee?.president?.id === user.id
            );
    }

    canVote(user: User): boolean {
        return user.isValid()
            && user.can('agenda.voting')
            && this.id > 0
            && this.schedule === 'live'
            && (this.agendas.length > 0 || this.procedures.length > 0)
            && (
                this.agendas.filter(item => item.vote_allowed && item.vote_result === 'pending' && item.agenda_status === 'vote').length > 0
                || this.procedures.filter(item => item.vote_allowed && item.vote_result === 'pending' && item.agenda_status === 'vote').length > 0
            )
            && (
                (this.committee?.users || []).filter(item => item.id === user.id).length > 0
                || this.committee?.president?.id === user.id
            );
    }

    canVoteAsProxy(user: User, voteForUser: UserBase, agenda: Agenda): boolean {
        return user.isValid()
            && user.can('agenda.voting_proxy')
            && agenda?.id > 0
            && agenda?.status === 'active'
            && agenda?.agenda_status === 'vote'
            && this.id > 0
            && this.schedule === 'live'
            && (
                (this.committee?.users || []).filter(item => item.id === voteForUser.id).length > 0
                || this.committee?.president?.id === voteForUser.id
            );

    }

    isVideoAvailable(user: User, liveOnly: boolean = false): boolean {
        if (this.schedule === 'live') {
            // Determine which video should be visible
            if (this.room?.id && user?.id && (this.canJoin(user) || user.canAnyOf(['meeting.update', 'agenda.voting']))) {
                return true;
            } else if (this.live_video_url?.length) {
                return true;
            }
        } else if (!liveOnly && this.schedule === 'past' && this.videos?.length) {
            return true
        }

        return false;
    }
}
