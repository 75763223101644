import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
    {
        canActivate: [AuthGuard],
        path: 'about',
        loadChildren: () => import('src/app/about/about.module').then(m => m.AboutModule),
        data: {title: 'Относно'}
    }, {
        canActivate: [AuthGuard],
        path: 'committees',
        loadChildren: () => import('src/app/committees/committees.module').then(m => m.CommitteesModule),
        data: {title: 'Комисии'}
    }, {
        canActivate: [AuthGuard],
        path: 'councilors',
        loadChildren: () => import('src/app/councilors/councilors.module').then(m => m.CouncilorsModule),
        data: {title: 'Общински съветници'}
    }, {
        canActivate: [AuthGuard],
        path: 'mandates',
        loadChildren: () => import('src/app/mandates/mandates.module').then(m => m.MandatesModule),
        data: {
            permissions: ['mandate.index'],
            title: 'Мандати'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'profile',
        loadChildren: () => import('src/app/profile/profile.module').then(m => m.ProfileModule),
        data: {
            guest: false,
            title: 'Личен профил'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'users',
        loadChildren: () => import('src/app/users/users.module').then(m => m.UsersModule),
        data: {
            title: 'Потребители',
            permissions: ['user.index']
        }
    }, {
        canActivate: [AuthGuard],
        path: 'political-groups',
        loadChildren: () => import('src/app/parties/parties.module').then(m => m.PartiesModule),
        data: {
            title: 'Политически групи',
            permissions: ['party.index']
        }
    }, {
        canActivate: [AuthGuard],
        path: 'rooms',
        loadChildren: () => import('src/app/rooms/rooms.module').then(m => m.RoomsModule),
        data: {
            title: 'Стаи',
            permissions: ['user.index']
        }
    }, {
        canActivate: [AuthGuard],
        path: 'auth',
        loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule),
        data: {guest: true}
    }, {
        canActivate: [AuthGuard],
        path: '',
        loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule)
    }, {
        path: 'errors',
        loadChildren: () => import('src/app/errors/errors.module').then(m => m.ErrorsModule)
    }, {
        path: '**',
        redirectTo: '/errors/404'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {initialNavigation: 'enabled'}),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
