import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

type ReactNativeEvents = ('route'|'logout');

@Injectable({
    providedIn: 'root'
})
export class ReactNativeService {
    protected messages: Subject<any> = new Subject();
    protected urlChange: boolean = false;

    constructor() { }

    protected isReactNativeWebView(): boolean {
        return 'ReactNativeWebView' in window
            && window['ReactNativeWebView']
            && 'postMessage' in window['ReactNativeWebView'];
    }

    protected sendData(event: ReactNativeEvents, data: any) {
        if (typeof window === 'undefined' || !window) {
            return;
        }

        if (this.isReactNativeWebView()) {
            window['ReactNativeWebView'].postMessage(JSON.stringify({
                event,
                data,
            }));
        }
    }

    messageReceived(data: any): void {
        this.messages.next(data);
    }

    notifyUrlChange(url: string) {
        if (!this.urlChange) { // skip first time
            this.urlChange = true;
            return;
        }
        this.sendData('route', url);
    }

    notifyLogout() {
        this.sendData('logout', null);
    }

    onLogout(): Promise<any> {
        let subscription = null;
        let timeout = null;

        const cleanUp: (data?: any) => void = (data?: any): void => {
            timeout && clearTimeout(timeout);
            subscription && subscription.unsubscribe();
        }

        return new Promise((resolve, reject) => {
            if (!this.isReactNativeWebView()) {
                resolve(null);
            } else {
                subscription = this.messages.pipe(share()).subscribe(data => {
                    cleanUp(data);
                    resolve(null);
                });
                this.notifyLogout();

                // if nothing is received => resolve anyway so user don't wait to log out
                timeout = setTimeout(() => {
                    cleanUp();
                    resolve(null);
                }, 500);
            }
        });
    }
}
