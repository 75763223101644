import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { User } from './../models/user.model';

export interface UsersListFilter {
    limit?: number,
    page?: number,
};

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(
        private api: ApiService,
    ) { }

    getProfile(): Observable<{data: User}> {
        return this.api.get<{data: User}>("/profile");
    }

    updateProfile({
        name_first = undefined,
        name_last = undefined,
        name_middle = undefined,
        email = undefined,
        phone = undefined,
        description = undefined,
        address = undefined,
        password = undefined,
        photo = undefined,
        current_password = undefined,
    }): Observable<{data: User}> {
        return this.api.post<{data: User}>('/profile', {
            name_first,
            name_last,
            name_middle,
            email,
            phone,
            description,
            address,
            password,
            photo,
            current_password
        });
    }
}
