import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DomService } from 'src/app/core/services/dom.service';
import { MainmenuService } from 'src/app/core/services/mainmenu.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'main-sidebar',
    templateUrl: './main-sidebar.component.html',
    styleUrls: ['./main-sidebar.component.scss']
})
export class MainSidebarComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    @Input() title: string = '';
    @Input() hasLive: boolean = false;

    @Output() onLiveClick: EventEmitter<Event> = new EventEmitter();

    showMenu: boolean = false;
    user: User = null;
    isCommitteesPage: boolean = false;
    logoPrefix: string = environment.production ? '/app/' : '/';

    constructor(
        private mainMenu: MainmenuService,
        private authService: AuthService,
        private domService: DomService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        const menuSubscribe = this.mainMenu.changes().subscribe(visible => {
            this.showMenu = visible;
        });
        this.subscriptions.push(menuSubscribe);

        const authSubscribe = this.authService.getStatus().subscribe(status => {
            if (!status.isSigned) {
                this.user = null;
            } else {
                this.user = this.authService.user;
            }
        });
        this.subscriptions.push(authSubscribe);

        this.committeesPageCheck();
        const routeSubscribe = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => this.committeesPageCheck());
        this.subscriptions.push(routeSubscribe);

    }

    ngOnDestroy(): void {
        this.subscriptions.map(item => item && item.unsubscribe());
    }

    hideMenu(event?: Event): void {
        this.mainMenu.hide();
    }

    menuClickOutside(event?: Event): void {
        if (!this.domService.closestTo(event.target, '.menu-toggle')) {
            this.mainMenu.hide();
        }
    }

    onLive(event?: Event): boolean {
        event && event.preventDefault();
        this.onLiveClick.emit(event);
        return false;
    }

    protected committeesPageCheck(): void {
        const pos = this.router.url.indexOf('?');
        const url = pos !== -1
            ? this.router.url.slice(0, pos)
            : this.router.url;
        this.isCommitteesPage = url.indexOf('/committees') === 0 && url.indexOf('/committees/municipality') === -1;
    }
}
