<div class="video-wrapper" *ngIf="isPinned && active"></div>

<div class="main-video" [class.fixed-video]="isPinned" [class.minimize-video]="isPinnedMinimized" cdkDrag cdkDragBoundary="body" #mainVideo>
    <div class="video-toolbar justify-content-between align-items-center px-2" [class.d-flex]="isPinned" [class.d-none]="!isPinned" cdkDragHandle>
        <small class="text-nowrap">
            <i class="fas fa-arrows-alt"></i>
            Видео
        </small>

        <div class="d-flex justify-content-between align-items-center text-nowrap flex-nowrap">
            <button (click)="isPinnedMinimized = !isPinnedMinimized"
                    type="button"
                    class="btn btn-dark btn-sm my-1 py-0 px-1"
            >
                <ng-container *ngIf="isPinnedMinimized">
                    <i class="fas fa-expand fa-fw"></i>
                    Разтегли видео

                </ng-container>
                <ng-container *ngIf="!isPinnedMinimized">
                    <i class="fas fa-compress fa-fw"></i>
                    Свий видео
                </ng-container>
            </button>

            <button *ngIf="showClose"
                    (click)="onClose($event)" type="button"
                    class="btn btn-danger btn-sm my-1 py-0 px-1 ml-1"
            ><i class="fa fa-times fa-fw"></i></button>
        </div>
    </div>

    <div class="video-wrapper">
        <div class="loading text-center d-flex bg-dark justify-content-center align-items-center flex-column w-100 text-light p-2 position-absolute" style="inset:0">
            <div class="spinner-border" style="width: 4rem; height: 4rem; margin: 2rem">
                <span class="sr-only">Зареждане...</span>
            </div>
        </div>

        <div #mainVideo>
            <iframe
                *ngIf="liveUrl?.length && (!isZoomUrl || enterZoomUrl)"
                [src]="liveUrl | safe:'resource'"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; camera; microphone; fullscreen"
                scrolling="no"
                allowfullscreen
                webkitallowfullscreen
                mozallowfullscreen
            ></iframe>

            <div class="joining" *ngIf="isZoomUrl && !enterZoomUrl" >
                <button class="btn btn-primary" (click)="enterZoomUrl = true; $event.preventDefault()">
                    <i class="fas fa-people-arrows fa-fw mr-2"></i>
                    Присъединяване към срещата
                </button>
            </div>
        </div>

        <div #mainVideo *ngIf="!liveUrl?.length && meeting?.videos?.length" [innerHtml]="meeting?.videos[videoIndex]?.embed | safe:'html'"></div>

        <div *ngIf="meeting && !liveUrl?.length" class="d-flex bg-dark justify-content-center align-items-center flex-column w-100 h-100 text-light p-2 position-absolute" style="inset:0">
            <ng-container *ngIf="meeting?.videos?.length">
                <i class="fa fa-spinner fa-pulse fa-fw" style="font-size: 6vw"></i>
            </ng-container>

            <ng-container *ngIf="!meeting?.videos?.length">
                <i class="fas fa-video-slash text-muted" style="font-size: 6vw"></i>
                <h3 class="text-center mt-2 mb-0 p-0">Неналично видео</h3>
            </ng-container>
        </div>
    </div>
</div>

<nav aria-label="Videos List" *ngIf="meeting?.schedule !== 'live' && meeting?.videos?.length > 1 && active">
    <ul class="pagination justify-content-center pagination-sm mt-2">
        <li class="page-item" [class.active]="videoIndex == index" *ngFor="let video of meeting?.videos; let index = index">
            <a class="page-link px-3" (click)="videoIndex = index; $event.preventDefault();" href="#">{{ index + 1 }}</a>
        </li>
    </ul>
</nav>
