import { environment as global } from './environment.global';

export const environment = {
    ...global,

    domain: 'dev.server.varco.tech',
    scheme: 'https',
    port: null,
    webSockets: {
        ... global.webSockets,

        wsHost: 'dev.server.varco.tech',
        wsPort: 80,
        wssPort: 443,
        wsPath: '/live'
    }
};
