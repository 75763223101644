import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { UserBase } from 'src/app/core/models/user-base.model';
import { User } from 'src/app/core/models/user.model';

export type AvatarSize = ('default'|'small'|'medium'|'big'|'huge');
@Component({
    selector: 'avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnDestroy {
    protected innerSize: AvatarSize = 'default';

    urlWidth: number = 36*2;

    @Output() onRemove: EventEmitter<any> = new EventEmitter();

    @Input() photo: string = null;
    @Input() user: User|UserBase = null;
    @Input() enableRemoving: boolean = false;
    @Input() urlResize: boolean = true; // adds ?w=[number] to the end of URL's
    @Input() removingTitle: string = 'Премахни аватар';
    @Input() status: boolean = null;

    @Input()
    set size(size: AvatarSize) {
        if (this.innerSize !== size) {
            this.innerSize = size;

            if (this.urlResize) {
                if (size === 'default') {
                    this.urlWidth = 36*2;
                } else if (size === 'small') {
                    this.urlWidth = 60*2;
                } else if (size === 'medium') {
                    this.urlWidth = 100*2;
                } else if (size === 'big') {
                    this.urlWidth = 150*2;
                } else if (size === 'huge') {
                    this.urlWidth = 350*2;
                }
            }
        }
    }
    get size() {
        return this.innerSize;
    }

    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onRemoveClicked(event: Event): boolean {
        event.preventDefault();
        this.onRemove.emit();
        return false;
    }

}
