import { AbstractControl, ValidatorFn } from '@angular/forms';

interface ReturnValue {
    [key: string]: {
        requiredValue: {
            min?: number,
            max?: number,
            leaveBlank?: boolean
        },
        actualValue: any,
    }
};

/**
 * Check for control's value is between min and max number, and flag if it can be leave blank (no number at all)
 */
export const betweenNumbers = (min: number = null, max: number = null, leaveBlank: boolean = false): ValidatorFn => {
    return (control: AbstractControl): ReturnValue => {
        let num = control.value;

        if (leaveBlank && (typeof num === 'undefined' || !num || !('' + num).length)) {
            return null;
        } else if (isNaN(num) || (min && num < min) || (max && num > max)) {
            return {
                betweennumbers: {
                    requiredValue: {
                        min,
                        max,
                        leaveBlank
                    },
                    actualValue: num
                }
            };
        } else {
            return null;
        }
    };
};
