import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/core/models/user.model';

@Pipe({
    name: 'can'
})
export class CanPipe implements PipeTransform {
    transform(user: any|User, ...args: string[]): boolean {
        return (
            user instanceof User ? user
                : Object.assign(new User, user)
        ).can(args);
    }
}
