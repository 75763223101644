import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { MainmenuService } from 'src/app/core/services/mainmenu.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/models/user.model';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MenuItem } from 'primeng/api';


@Component({
    selector: 'main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy {
    @Input() title: string = '';
    @Input() breadcrumb: MenuItem[] = [];

    showAuth: boolean = false;
    showMenu: boolean = false;
    showProfileMenu: boolean = false;

    isAuthReady: boolean = false;
    user: User = new User();
    authMode: ('auth'|'reset') = 'auth';
    authToken: string = null;
    authEmail: string = null;
    logoPrefix: string = environment.production ? '/app/' : '/';
    isUserValid: boolean = false;

    private menuSubscribe: Subscription = null;
    private authReadySubscribe: Subscription = null;
    private authStatusSubscribe: Subscription = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private mainMenu: MainmenuService,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.menuSubscribe = this.mainMenu.changes().subscribe(visible => {
            this.showMenu = visible;
        });

        this.authReadySubscribe = this.authService.getAuthReady()
            .pipe(filter(status => status)).subscribe(ready => {
                this.isAuthReady = String(ready).toLowerCase() === 'true';
            });

        this.user = this.authService.user;
        this.isUserValid = this.user.isValid();

        this.authStatusSubscribe = this.authService.getStatus().subscribe(status => {
            this.user = null;

            setTimeout(() => {
                this.user = this.authService.user;
                this.isUserValid = this.user.isValid();
            });
        });

        this.route.queryParams.subscribe((data: any) => {
            if (data && 'token' in data && (data.token || '').length) {
                this.authToken = data.token;
                this.authEmail = data.email || '';
                this.authMode = 'reset';
                this.showAuth = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.menuSubscribe && this.menuSubscribe.unsubscribe();
        this.authReadySubscribe && this.authReadySubscribe.unsubscribe();
        this.authStatusSubscribe && this.authStatusSubscribe.unsubscribe();
    }

    onLogin(event?: any): void {
        this.authMode = 'auth';
        this.showAuth = true;
    }

    onLogout(event?: Event): void {
        event.preventDefault();
        this.authService.logout().subscribe(status => {
            const currentUrl = this.router.url;

            this.router.navigate(['/']).then(() => {
                this.router.navigateByUrl(currentUrl);
            });
        });
    }

    onAuthClose(event?: any): void {
        this.showAuth = false;
    }

    toggleMenu(event?: any): void {
        this.mainMenu.toggle();
    }
}
