<alert type="error" [message]="messageError" (remove)="messageError = ''"></alert>
<alert type="success" [message]="messageSuccess" (remove)="messageSuccess = ''"></alert>

<p *ngIf="!messageSuccess?.length">Моля, въведете своята <strong>нова</strong> парола два пъти!</p>

<form class="my-3" (submit)="onSubmit($event)" [formGroup]="form" autocomplete="off">
    <div class="form-group row">
        <label for="inputResetPassword" class="col-xs-12 col-sm-4 col-form-label">Парола</label>
        <div class="col-xs-12 col-sm-8">
            <input
                type="password"
                class="form-control"
                id="inputResetPassword"
                [attr.disabled]="loading || messageSuccess?.length ? true : null"
                formControlName="password"
                placeholder="Въведете нова парола"
                autocomplete="off"
                [class.is-invalid]="errors?.password?.status === 'INVALID'"
            />
            <div class="invalid-feedback" *ngIf="errors?.password?.errors?.required">Полето е задължително</div>
            <div class="invalid-feedback" *ngIf="errors?.password?.errors?.minlength">Полето е по-малко от 8 символа</div>
            <div class="invalid-feedback" *ngIf="errors?.password?.errors?.any">{{ errors?.password?.errors?.any }}</div>
        </div>
    </div>
    <div class="form-group row">
        <label for="inputResetPasswordAgain" class="col-xs-12 col-sm-4 col-form-label">Парола (отново)</label>
        <div class="col-xs-12 col-sm-8">
            <input
                type="password"
                class="form-control"
                id="inputResetPasswordAgain"
                [attr.disabled]="loading || messageSuccess?.length ? true : null"
                formControlName="passwordConfirm"
                autocomplete="off"
                placeholder="Въведете нова парола отново"
                [class.is-invalid]="errors?.passwordConfirm?.status === 'INVALID'"
            />
            <div class="invalid-feedback" *ngIf="errors?.passwordConfirm?.errors?.equalto">Паролата не съвпада</div>
            <div class="invalid-feedback" *ngIf="errors?.passwordConfirm?.errors?.any">{{ errors?.passwordConfirm?.errors?.any }}</div>
        </div>
    </div>
</form>
